<template>
    <div class="text-center">
        <img src="/svg/not-found-banner.svg" alt="">
        <h3 class="title">{{ $t('No conversations found.') }}</h3>
        <button v-if="company" class="button primary-button cursor-pointer" @click="moveToNewConversationOrCandidatePage">
            {{ $t('Go to candidates') }}
        </button>
    </div>
</template>

<script>
import {first} from "lodash";
import {UPDATE_PAGE_STATE} from "../../constants/action-type";
import client from "../../app/api/Client";
import {mapActions, mapState} from "vuex";

export default {
    name: "ConversationNotFound",

    computed: {
        ...mapState(['company']),
    },

    methods: {

        ...mapActions([UPDATE_PAGE_STATE]),

        async moveToNewConversationOrCandidatePage() {
            let queryParams = {params: {page: 1, ...this.form, 'has-conversation': false}};

            try {
                let data;
                if (this.company) {
                    ({data: {data}} = await this.getCompanyNewConversationApplicantList(queryParams));
                } else {
                    ({data: {data}} = await this.getCandidateNewConversationApplicantList(queryParams));
                }

                if (data.data.length > 0) {
                    let applicantId = first(data.data)?.id;
                    this[UPDATE_PAGE_STATE]({applicantId, showNewConversation: true});
                    await this.$router.push({name: 'conversations', params: {id: applicantId, 'new': 'new'}});
                } else {
                    await this.$router.push({name: 'company.candidates'});
                }
            } catch (e) {

            }
        },

        async getCompanyNewConversationApplicantList(params) {
            return await client().get(`conversation/applicants`, params);
        },

        async getCandidateNewConversationApplicantList(params) {
            return await client().get(`conversation/applicants`, params);
        },
    }
}
</script>

<style scoped>
.message__wrapper.not-found-page img {
    max-width: 100%;
}
.message__wrapper.not-found-page .title {
    font-size: 21px;
    margin-top: 25px;
    margin-bottom: 20px;
}
.message__wrapper.not-found-page p {
    font-size: 14px;
    color: #7d8091;
    margin-bottom: 25px;
}
.message__wrapper.not-found-page .button {
    display: inline-block;
}
</style>
